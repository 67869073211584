.invoiceupload {
  margin-bottom: 5px;
}

#invoicemessage {
  padding: 0.375rem 0.75rem;
}

.documentupload {
  margin-bottom: 5px;
}

.uploadprogressbars {
  margin-bottom: 5px;
}

.multiupload {
  margin-bottom: 10px;
}

.person-noedit {
  border-width: 0 !important;
  background-color: white !important;
  cursor: pointer !important;
  display: inline !important;
  width: 90% !important;
}

.person-edit {
  display: inline !important;
  width: 90% !important;
}